import http, { getAuthConfig } from "./http";

const BASE_URL = "/broadcast";

const sendTestEmail = (data, callback) => {
	http
		.post(`${BASE_URL}/email/send-test-email`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const sendEmail = (data, callback) => {
	http
		.post(`${BASE_URL}/email/send`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const draftEmail = (data, callback) => {
	http
		.post(`${BASE_URL}/email/draft`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const schedule = (data, callback) => {
	http
		.post(`${BASE_URL}/email/schedule`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const draftUpdate = (data, callback) => {
	http
		.post(`${BASE_URL}/email/update`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const sendFromDraft = (data, callback) => {
	http
		.post(`${BASE_URL}/email/send-from-draft`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const uploadImage = (data, callback) => {
	http
		.post(`${BASE_URL}/image/upload`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_BROADCAST = {
	sendTestEmail,
	sendEmail,
	draftEmail,
	draftUpdate,
	sendFromDraft,
	uploadImage,
	schedule,
};
