import http, { getAuthConfig } from "./http";

const BASE_URL = "/broadcast/sender";

const createSender = (data, callback) => {
	http
		.post(`${BASE_URL}/create`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const updateSender = (data, callback) => {
	http
		.post(`${BASE_URL}/update`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const deleteSender = (data, callback) => {
	http
		.post(`${BASE_URL}/delete`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_BROADCAST_SENDER = {
	createSender,
	updateSender,
	deleteSender,
};
