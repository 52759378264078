import http, { getAuthConfig } from "./http";

const BASE_URL = "/formgate";

export const FORMGATE_TARGET_TYPE = {
	number: 1,
	date: 2,
	percentage: 3,
	average: 4,
};

const getSize = (data, callback) => {
	http
		.post(`${BASE_URL}/size`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const createSize = (data, callback) => {
	http
		.post(`${BASE_URL}/size/create`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const createIpp = (data, callback) => {
	http
		.post(`${BASE_URL}/ipp/create`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const updateIpp = (data, callback) => {
	http
		.post(`${BASE_URL}/ipp/update`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const deleteIpp = (data, callback) => {
	http
		.post(`${BASE_URL}/ipp/delete`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const updateIpt = (data, callback) => {
	http
		.post(`${BASE_URL}/ipt/update`, data, getAuthConfig())
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

const syncMethod = (year, month, employee, callback) => {
	http
		.get(
			`/method/monitor?year=${year}&month=${month}&employee=${employee}`,
			getAuthConfig(),
		)
		.then((res) => callback({ payload: res.data, error: null, success: true }))
		.catch((error) =>
			callback({ payload: null, error: error.response, success: false }),
		);
};

export const SERVICE_FORMGATE = {
	getSize,
	createSize,
	createIpp,
	updateIpp,
	deleteIpp,
	updateIpt,
	syncMethod,
};
